body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
* {
  color: inherit;
}
body {
  background: #fff;
  color: #333;
}
::-moz-selection {
  background: #294b78;
  color: #fff;
}
::selection {
  background: #294b78;
  color: #fff;
}
* {
  font: inherit;
  line-height: inherit;
  text-transform: inherit;
}
html {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.33;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 1024px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 1280px) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 1440px) {
  html {
    font-size: 20px;
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 22px;
  }
}
small {
  font-size: 0.875rem;
}
.clesy-typography-caption {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
}
i {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  vertical-align: top;
}
html {
  height: 100%;
  min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: url(../images/background.jpg) fixed no-repeat center center / cover;
}
* {
  box-sizing: border-box;
}
#root {
  height: 100%;
}
.clesy-app {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  min-height: 100%;
  overflow-x: hidden;
}
.clesy-app .clesy-nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: transform 0.25s;
  width: 13em;
  transform: translateX(-13em);
}
@media (min-width: 45em) {
  .clesy-app .clesy-nav {
    position: static;
    order: -1;
    transform: none;
  }
}
.clesy-app .clesy-app-main {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  transition: transform 0.25s;
}
.clesy-app .clesy-app-main .nav-toggle {
  flex: 0 0 auto;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #fff;
  width: 100%;
  background: rgba(0, 16, 32, 0.75);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.66);
  z-index: 999;
}
.clesy-app .clesy-app-main .nav-toggle i {
  font-size: 2em;
  padding: 0 1rem;
}
.clesy-app .clesy-app-main .nav-toggle .clesy-logo {
  font-size: 1.5em;
}
@media (min-width: 45em) {
  .clesy-app .clesy-app-main .nav-toggle {
    display: none;
  }
}
.clesy-app .clesy-app-main .view-container {
  position: relative;
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;
}
.clesy-app.nav-open .clesy-nav {
  transform: translateX(0);
}
.clesy-app.nav-open .clesy-app-main {
  transform: translateX(13em);
}
@media (min-width: 45em) {
  .clesy-app.nav-open .clesy-app-main {
    transform: none;
  }
}
table th,
table td {
  padding: 0;
  border: 0;
  margin: 0;
}
#static-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}
#static-content .static-content-wrap {
  max-width: 40em;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
#static-content .clesy-logo {
  font-size: 3em;
  margin: 0.5em auto;
}
#static-content .clesy-spinner {
  font-size: 1.5em;
  margin: 1em 0;
}
#static-content .clesy-spinner div {
  background-color: #fff;
}
#static-content .error-hint {
  max-width: 30em;
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
}
#static-content .error-hint a {
  text-decoration: none;
  display: inline-block;
  padding: 0.25em 0.5em;
  margin: 0 0.5em;
}
#static-content .error-hint a:hover {
  background: rgba(255, 255, 255, 0.25);
}
.my-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.mt-1 {
  margin-top: 1em;
}
.switch {
  font-size: 1em;
  display: inline-block;
  margin-left: 0.5em;
  cursor: pointer;
}
.clesy-item {
  display: flex;
  flex-flow: row nowrap;
  padding: 0.75em 1em;
  align-items: center;
}
.clesy-item .content {
  flex: 1 1 auto;
}
.clesy-item .description {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
}
.clesy-item .icon-right {
  margin: 0 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(51, 51, 51, 0.75);
}
.clesy-item .icon-right i {
  font-size: 2em;
}
.clesy-item .icon-right span {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  text-transform: uppercase;
}
.clesy-item.empty .content {
  opacity: 0.5;
  font-style: italic;
}
.clesy-field {
  position: relative;
  padding-bottom: 1em;
}
.clesy-field abbr.required {
  color: #660000;
  text-decoration: none;
}
.clesy-field .clesy-value {
  background: #fff;
}
.clesy-field.floating-label {
  padding-top: 1.5em;
}
.clesy-field.floating-label input,
.clesy-field.floating-label select,
.clesy-field.floating-label textarea {
  font-size: 1em;
  padding: 0.25em 0;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #999;
  overflow: hidden;
}
.clesy-field.floating-label input:focus,
.clesy-field.floating-label select:focus,
.clesy-field.floating-label textarea:focus {
  outline: none;
}
.clesy-field.floating-label input:required,
.clesy-field.floating-label select:required,
.clesy-field.floating-label textarea:required {
  box-shadow: none;
}
.clesy-field.floating-label input:invalid,
.clesy-field.floating-label select:invalid,
.clesy-field.floating-label textarea:invalid {
  border-bottom: 1px solid #660000;
}
.clesy-field.floating-label option {
  font-size: 1em;
  padding: 0;
}
.clesy-field.floating-label label {
  display: block;
  position: absolute;
  pointer-events: none;
  opacity: 0.5;
  left: 0;
  top: 1.5em;
  padding: 0.25rem 0;
  transition: 0.2s ease all;
  font-size: 1em;
  font-weight: 400;
  text-transform: none;
}
.clesy-field.floating-label :focus ~ label,
.clesy-field.floating-label .not-empty ~ label,
.clesy-field.floating-label .value ~ label,
.clesy-field.floating-label .clesy-clickable ~ label {
  top: 0;
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
}
.clesy-field.floating-label :focus ~ label {
  opacity: 1;
  color: #294b78;
}
.clesy-field.floating-label .bar {
  position: relative;
  display: block;
  width: 100%;
}
.clesy-field.floating-label .bar:before,
.clesy-field.floating-label .bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: #294b78;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.clesy-field.floating-label .bar:before {
  left: 50%;
}
.clesy-field.floating-label .bar:after {
  right: 50%;
}
.clesy-field.floating-label :focus ~ .bar:before,
.clesy-field.floating-label :focus ~ .bar:after {
  width: 50%;
}
.clesy-field.floating-label .message {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
  position: absolute;
}
.clesy-field.floating-label :invalid ~ .message {
  color: #660000;
}
.clesy-field.floating-label.valid > .message {
  color: #006600;
}
.clesy-field.floating-label.valid > .bar:before,
.clesy-field.floating-label.valid > .bar:after {
  background: #006600;
}
.clesy-field.right-label input,
.clesy-field.right-label textarea {
  margin-left: 0;
  margin-right: 0.5em;
}
.clesy-field.zip-city-field {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
}
.clesy-field.zip-city-field .clesy-field:first-child {
  flex: 0 0 5em;
}
.clesy-field.zip-city-field .clesy-field:last-child {
  flex: 1 1 auto;
}
.clesy-field.street-nr-field {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
}
.clesy-field.street-nr-field .clesy-field:first-child {
  flex: 1 1 auto;
}
.clesy-field.street-nr-field .clesy-field:last-child {
  flex: 0 0 5em;
}
.clesy-field.checkbox-field {
  vertical-align: top;
  padding-bottom: 0;
}
.clesy-field.radio-field label {
  display: block;
}
.button {
  font-size: 1em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.07142857em;
  background: transparent;
  color: #333;
  display: inline-block;
  cursor: pointer;
  border-radius: 1.165em;
  border: 1px solid #333;
  padding: 0.5em 2em;
  transition: opacity 0.2s;
}
.button.primary {
  background-color: #294b78;
  color: #fff;
  text-align: center;
  box-shadow: 0 2px 0.125em rgba(0, 0, 0, 0.5);
  border-color: #294b78;
}
.button[disabled] {
  opacity: 0.5;
}
.button.link {
  color: #333;
  background: transparent;
  text-transform: none;
  font-weight: 400;
  letter-spacing: 0;
  box-shadow: none;
  border-radius: 0;
}
.button.link.primary {
  color: #294b78;
}
.button i:first-child {
  margin-right: 0.25em;
}
.clesy-panel {
  background-color: #fff;
  box-shadow: 0 3px 0.25em rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}
.clesy-panel .header {
  background: #294b78;
  padding: 1em 0.5em;
}
.clesy-panel .header .title {
  font-size: 1.42857143em;
  font-weight: 500;
  text-transform: none;
  color: #fff;
}
.clesy-panel .content {
  padding: 1em;
}
.clesy-panel .content > h1:first-child,
.clesy-panel .content > h2:first-child,
.clesy-panel .content > h3:first-child {
  margin-top: 0;
}
.clesy-panel .header + .content {
  padding-top: 1em;
}
.clesy-error-message .message {
  color: #660000;
}
.clesy-list > * {
  border-bottom: 1px solid #ccc;
  background: #fff;
}
.clesy-list > *:first-child {
  border-top: 1px solid #ccc;
}
.clesy-clickable {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.clesy-clickable .background {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: transparent;
  opacity: 1;
  transition: all 0.1s;
}
.clesy-clickable:hover .background {
  width: 100%;
  background: rgba(41, 75, 120, 0.1);
}
.clesy-clickable.mouse-down .background {
  width: 100%;
  background: rgba(41, 75, 120, 0.5);
}
.clesy-clickable .ripple {
  content: '';
  display: block;
  position: absolute;
  border-radius: 50%;
  transform-origin: 50% 50% 0;
  background: rgba(255, 255, 255, 0.5);
  width: 1px;
  height: 1px;
}
.clesy-clickable.mouse-down .ripple {
  transform: scale(1);
  animation: ripple-animation 1s linear infinite;
}
@keyframes ripple-animation {
  from {
    transform: scale(1);
    opacity: 0.4;
  }
  to {
    transform: scale(200);
    opacity: 0;
  }
}
.clesy-card {
  background-color: #fff;
  box-shadow: 0 3px 0.25em rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  position: relative;
  max-width: 40em;
  width: 100%;
  margin: 0 auto;
  padding: 2.5% 5%;
}
.clesy-card h2 {
  font-size: 1.42857143em;
  font-weight: 500;
  text-transform: none;
}
.clesy-card h3 {
  font-size: 1.14285714em;
  font-weight: 400;
  text-transform: none;
}
.clesy-card + .clesy-card {
  margin-top: 1em;
}
.clesy-card > * {
  transition: opacity 0.25s;
}
.clesy-card > .clesy-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(41, 75, 120, 0.75);
  font-size: 2em;
  transition: visibility 0s linear 0.25s, opacity 0.25s;
  opacity: 0;
  visibility: hidden;
}
.clesy-card > .clesy-spinner div {
  background: #fff;
}
.clesy-card.processing > * {
  opacity: 0.5;
}
.clesy-card.processing > .clesy-spinner {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}
.clesy-card-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.clesy-logo {
  text-indent: -9999px;
  background: url(../images/clesyclean-logo.png) no-repeat left center / contain;
  height: 1em;
  width: 2.8852459em;
  margin-bottom: 0.15em;
}
.clesy-dialog {
  display: none;
  opacity: 0;
  transition: opacity 0.2s;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.clesy-dialog.active {
  display: flex;
  opacity: 1;
}
.clesy-dialog .clesy-card {
  position: relative;
  padding: 0;
  max-height: 100%;
  overflow: auto;
}
.clesy-nav {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.33);
  color: #fff;
}
.clesy-nav .clesy-logo {
  flex: 0 0 auto;
  font-size: 3em;
  margin: 0.5em 0;
}
.clesy-nav .user {
  text-align: center;
}
.clesy-nav .user i {
  font-size: 4em;
  opacity: 0.5;
}
.clesy-nav .user .label {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
}
.clesy-nav .nav-items {
  margin: 2em 0;
  flex: 1 1 auto;
  width: 100%;
}
.clesy-nav .nav-items .item a {
  display: block;
  padding: 0.75em 1em 0.75em 1em;
  cursor: pointer;
}
.clesy-nav .nav-items .item a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.clesy-nav .nav-items .item a i {
  margin-right: 0.5em;
  opacity: 0.5;
}
.clesy-nav .nav-items .item.current a {
  background: rgba(255, 255, 255, 0.2);
}
.clesy-nav .copyright {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
  font-size: 0.75em;
  margin: 1em 0;
  width: 100%;
  text-align: center;
}
.clesy-nav .copyright a {
  display: inline-block;
  padding: 0.25em 0.5em;
}
.clesy-collapsible {
  height: 0;
  visibility: hidden;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s, height 0s linear 0.2s;
}
.clesy-collapsible.expanded {
  height: auto;
  transform: scaleY(1);
  visibility: visible;
}
.clesy-account-item .title .email {
  display: inline-block;
  opacity: 0.75;
  margin-left: 0.5em;
}
.clesy-article-item {
  border-left: 1em solid #d0d0d0;
}
.clesy-article-item .price {
  padding: 0.5em;
  border-radius: 0.25em;
  text-align: right;
  min-width: 4.5em;
  margin-right: 1em;
}
.clesy-article-item .price .unit {
  opacity: 0.75;
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
}
.clesy-article-item .price .label {
  opacity: 0.75;
  font-size: 0.75em;
  font-weight: 500;
  text-transform: uppercase;
}
.clesy-article-item.permanent-agreement .price {
  background-color: #eea11e;
  color: #fff;
}
.clesy-article-item.promo-discount .price {
  background-color: #e81e26;
  color: #fff;
}
.clesy-article-item.highlight .price {
  background-color: #bccd30;
  color: #fff;
}
.clesy-article-category-item {
  cursor: pointer;
}
.clesy-article-category-item-title {
  flex-grow: 1;
}
.clesy-article-category-item--selected {
  background: rgba(41, 75, 120, 0.2);
}
.clesy-spinner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.clesy-spinner div {
  width: 1em;
  height: 1em;
  background-color: #333;
  margin: 0.125em;
  border-radius: 100%;
  display: block;
  -webkit-animation: clesy-spinner-bouncedelay 1.4s infinite ease-in-out both;
  animation: clesy-spinner-bouncedelay 1.4s infinite ease-in-out both;
}
.clesy-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.clesy-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes clesy-spinner-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes clesy-spinner-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.clesy-form-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.clesy-form-row .clesy-field {
  flex: 1 1 auto;
  margin: 0.5em 0;
}
.clesy-view {
  position: absolute;
  height: 100%;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transform-origin: 50% 50%;
  transform: scale(0);
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.clesy-view.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: transform 0.4s, opacity 0.4s;
}
.clesy-view > .clesy-view-main {
  height: 100%;
  overflow: auto;
}
@media (min-width: 45em) {
  .clesy-view > .clesy-view-main {
    padding: 5% 10% 5% 10%;
  }
}
.clesy-view > .clesy-view-main > .header {
  max-width: 40em;
  width: 100%;
  margin: 0 auto;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  color: #fff;
  padding-bottom: 1.5em;
}
.clesy-view > .clesy-view-main > .header h1 {
  flex: 1 1 auto;
  font-size: 1.42857143em;
  font-weight: 500;
  text-transform: none;
}
.clesy-view > .clesy-view-main > .header .close {
  cursor: pointer;
}
.clesy-view > .clesy-view-main > .content {
  max-width: 40em;
  width: 100%;
  margin: 0 auto;
  flex: 1 1 auto;
  z-index: 0;
}
.clesy-view > .clesy-view-main > .footer {
  max-width: 40em;
  width: 100%;
  margin: 0 auto;
  flex: 0 0 auto;
}
.clesy-login {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #fff;
  overflow-y: auto;
}
.clesy-login > * {
  margin: 1em;
}
.clesy-login .clesy-logo {
  font-size: 3em;
}
.clesy-login form {
  width: 16em;
}
.clesy-login .button-container {
  text-align: center;
  padding: 1em 0;
}
.clesy-login .legacyShop {
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;
}
.clesy-login .legacyShop .headLine h1 {
  font-style: italic;
  font-weight: 300;
}
.clesy-login .legacyShop .button-container a {
  font-size: 1em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.07142857em;
  background: transparent;
  color: #fff;
  display: inline-block;
  cursor: pointer;
  border-radius: 1.165em;
  border: 1px solid #fff;
  padding: 0.5em 2em;
  transition: opacity 0.2s;
}
.clesy-login .legacyShop .button {
  border-color: #fff;
  color: #fff;
}
.clesy-login .forgot-password {
  text-align: center;
}
.clesy-login .forgot-password .button {
  border: 0;
  text-transform: none;
  font-weight: normal;
  letter-spacing: inherit;
  color: #fff;
}
.clesy-login .register {
  text-align: center;
}
.clesy-login .register .text {
  font-style: italic;
  font-size: 1.25em;
}
.clesy-login .register .button {
  margin: 1em;
  border-color: #fff;
  color: #fff;
}
.clesy-login .clesy-field input {
  background: transparent;
}
.clesy-login .clesy-field input:invalid {
  border-color: #fff;
}
.clesy-login .clesy-field input:focus ~ label {
  opacity: 1;
  color: #fff;
}
.clesy-login .clesy-field .bar:before,
.clesy-login .clesy-field .bar:after {
  background: #fff;
}
.clesy-login .clesy-field label {
  color: #fff;
}
.clesy-login .error-message,
.clesy-login .logout-message {
  background-color: rgba(255, 255, 255, 0.25);
  padding: 0.5em 0.75em;
  border-radius: 0.25em;
}
.clesy-login .error-message {
  color: #660000;
}
.clesy-login .logout-message {
  color: #006600;
}
.clesy-login .form-container {
  display: block;
  position: relative;
}
.clesy-login .form-container .clesy-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  font-size: 2em;
}
.clesy-login .form-container .clesy-spinner div {
  background-color: #fff;
}
.clesy-login.pending .form-container form {
  opacity: 0.5;
}
.clesy-login.pending .form-container .clesy-spinner {
  visibility: visible;
}
.clesy-alert-warning {
  background: rgba(153, 102, 51, 0.1);
  color: #996633;
  padding: 1em;
  margin: 1em 0;
  border-radius: 0.25em;
}
.clesy-alert-error {
  background: rgba(102, 0, 0, 0.1);
  color: #660000;
  padding: 1em;
  margin: 1em 0;
  border-radius: 0.25em;
}
.clesy-alert-success {
  background: rgba(0, 102, 0, 0.1);
  color: #006600;
  padding: 1em;
  margin: 1em 0;
  border-radius: 0.25em;
}
.clesy-register-view {
  overflow: auto;
}
.clesy-register-view .signup-header {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
}
.clesy-register-view .signup-header h2 {
  color: #fff;
  font-size: 1.5em;
}
@media (min-width: 45em) {
  .clesy-register-view .signup-content {
    padding: 5% 10% 5% 10%;
  }
}
.clesy-register-view .clesy-logo {
  font-size: 2.5em;
}
.clesy-register-view .signup-select-site-header {
  display: block;
}
.clesy-register-view .signup-payment-logo {
  height: 1.5em;
  width: auto;
  margin: 0 0.75em;
}
.clesy-register-view .signup-payment-logo:first-child {
  margin-left: 0;
}
.clesy-register-view .signup-payment-logo:last-child {
  margin-right: 0;
}
.clesy-register-view .signup-form .clesy-card .clesy-card-header {
  display: block;
  text-align: center;
}
.clesy-register-view .signup-form .clesy-card .salutation-field,
.clesy-register-view .signup-form .clesy-card .title-field {
  flex: 0 0 47.5%;
}
.clesy-register-view .signup-form .clesy-card .submit-button {
  text-align: center;
  margin: 2em 0 1em 0;
}
.clesy-register-view .signup-form .clesy-card .clesy-field.radio-field label {
  display: flex;
  margin: 0.75em 0;
}
.clesy-register-view .signup-form .clesy-card .clesy-field.radio-field .option {
  flex: 1 1 auto;
}
.clesy-register-view .signup-form .clesy-card .clesy-field.radio-field .sub {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
}
.clesy-register-view .signup-form .clesy-card .clesy-field.radio-field input {
  vertical-align: middle;
}
@media (min-width: 480px) {
  .clesy-register-view .signup-form .clesy-card .first-name-field,
  .clesy-register-view .signup-form .clesy-card .last-name-field,
  .clesy-register-view .signup-form .clesy-card .email-field,
  .clesy-register-view .signup-form .clesy-card .phone-field,
  .clesy-register-view .signup-form .clesy-card .company-name-field,
  .clesy-register-view .signup-form .clesy-card .company-location-field,
  .clesy-register-view .signup-form .clesy-card .street-nr-field,
  .clesy-register-view .signup-form .clesy-card .zip-city-field,
  .clesy-register-view .signup-form .clesy-card .password-field,
  .clesy-register-view .signup-form .clesy-card .account-holder-field,
  .clesy-register-view .signup-form .clesy-card .account-number-field {
    flex: 0 0 47.5%;
  }
}
.clesy-register-view .cancel-button {
  padding: 2em 0;
  text-align: center;
}
.clesy-register-view .cancel-button .button {
  border-color: #fff;
  color: #fff;
}
.clesy-select-site .content,
.clesy-select-account .content {
  padding: 0;
}
.clesy-shop .articles {
  width: 100%;
  max-width: 40em;
  max-height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.clesy-shop .articles .header {
  padding: 1em 2em;
  box-shadow: 0 2px 0.125em rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.clesy-shop .articles .header .search {
  position: relative;
}
.clesy-shop .articles .header .search i {
  position: absolute;
  bottom: 0.25em;
  left: 0.25em;
  line-height: inherit;
}
.clesy-shop .articles .header .search input {
  border: 0;
  border-bottom: 1px solid #999;
  padding: 0.25em 0 0.25em 1.5em;
}
.clesy-shop .articles .content {
  overflow-y: auto;
  padding: 0;
}
.clesy-shop .articles .article {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 1em 2em;
  align-items: center;
  border-top: 1px solid #ccc;
}
.clesy-shop .articles .article .text {
  flex: 1 1 auto;
}
.clesy-shop .articles .article .text .description {
  color: rgba(51, 51, 51, 0.75);
  font-weight: 300;
  font-size: 0.875em;
  margin-top: 0.5em;
}
.clesy-shop .articles .article .price {
  flex: 0 0 auto;
  padding: 0.5em 0.75em;
  margin: 0 1em;
}
.clesy-shop .articles .article .add {
  flex: 0 0 auto;
  font-size: 2em;
  color: #999;
  cursor: pointer;
}
.clesy-shop .cart {
  width: 100%;
  max-width: 20em;
}
.clesy-shop .cart .item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0.25em 0;
}
.clesy-shop .cart .items {
  table-layout: fixed;
}
.clesy-shop .cart .totals {
  font-weight: 500;
  border-top: 1px solid #999;
  text-align: right;
}
.clesy-shop .cart .actions {
  margin-top: 2em;
  text-align: center;
}
.clesy-order-create .delivery-dates {
  padding: 0 1em;
}
.clesy-order-create .delivery-dates .label {
  opacity: 0.75;
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
}
.clesy-order-create .delivery-dates .dates {
  display: flex;
  flex-flow: row nowrap;
}
.clesy-order-create .delivery-dates .dates .delivery-date {
  flex: 1 1 auto;
}
.clesy-order-create .delivery-dates .attention {
  margin-top: 1em;
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
}
.clesy-order-create .delivery-dates .notification {
  background: rgba(153, 102, 51, 0.1);
  color: #996633;
  padding: 1em;
  border-radius: 0.25em;
  margin: 1em 0;
}
.clesy-order-create .delivery-dates .notification .vacation {
  font-weight: 500;
}
.clesy-order-create .cart-contents .empty-cart {
  opacity: 0.75;
  font-style: italic;
  text-align: center;
  padding: 1em;
}
.clesy-order-create .cart-contents .items .item:nth-child(odd) {
  background-color: #f6f6f6;
}
.clesy-order-create .cart-contents .items .description {
  padding: 0.25em 0;
}
.clesy-order-create .cart-contents .totals {
  border-top: 1px solid #ccc;
  padding: 0.5em 0;
  margin-bottom: 1em;
  border-bottom: 3px double #ccc;
}
.clesy-order-create .cart-contents .item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 1rem;
}
.clesy-order-create .cart-contents .item .description {
  flex: 1 1 auto;
}
.clesy-order-create .cart-contents .item .description .options {
  padding-left: 0.5em;
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
}
.clesy-order-create .cart-contents .item .quantity {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.clesy-order-create .cart-contents .item .quantity button {
  border: 0;
  background: transparent;
}
.clesy-order-create .cart-contents .item .quantity button[disabled] {
  opacity: 0.5;
}
.clesy-order-create .cart-contents .item .quantity i {
  font-size: 1.5em;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  line-height: 1;
  opacity: 0.75;
}
.clesy-order-create .cart-contents .item .remove {
  color: #294b78;
  cursor: pointer;
  min-width: 8em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
}
.clesy-order-create .cart-contents .item .remove i {
  font-size: 1.5em;
  color: #294b78;
  padding: 0 0.25rem;
  line-height: 1;
  vertical-align: middle;
}
.clesy-order-create .cart-contents .item .remove span {
  vertical-align: middle;
}
.clesy-order-create .cart-contents .item .line-total {
  min-width: 4em;
  text-align: right;
  white-space: nowrap;
}
.clesy-order-create .cart-contents .items .line-total {
  line-height: 2.5;
}
.clesy-order-create .cart-contents .total {
  font-weight: 500;
}
.clesy-order-create .cart-contents .tax {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
}
.clesy-order-create .cart-actions {
  text-align: center;
}
.clesy-order-create .available-vouchers {
  text-align: center;
}
.clesy-order-create .available-vouchers .voucher {
  display: inline-block;
  margin: 0.25em 0.5em;
  background-color: rgba(41, 75, 120, 0.1);
  padding: 0.25em 0.75em;
  border-radius: 1em;
  cursor: pointer;
}
.clesy-order-create .submit-button {
  padding: 1em 0;
  text-align: center;
}
.clesy-order-create .add-article-dialog {
  justify-content: flex-start;
}
@media (min-width: 40em) {
  .clesy-order-create .add-article-dialog {
    padding: 3em 0;
  }
}
.clesy-order-create .add-article-dialog .clesy-card {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}
.clesy-order-create .add-article-dialog .clesy-card-header {
  padding: 1em;
  border-bottom: 1px solid #ccc;
}
.clesy-order-create .add-article-dialog .clesy-card-header .search-term {
  display: block;
  position: relative;
  flex: 1 1 auto;
  margin: 0.66em 3em 0.66em 0;
}
.clesy-order-create .add-article-dialog .clesy-card-header .search-term input {
  padding: 0;
  border: 0;
  border-bottom: 1px solid #ccc;
  width: 100%;
}
.clesy-order-create .add-article-dialog .clesy-card-header .search-term .clear {
  position: absolute;
  top: 50%;
  margin-top: -0.65em;
  right: -1.25em;
  font-size: 1.5em;
  cursor: pointer;
}
.clesy-order-create .add-article-dialog .clesy-card-header .search {
  font-size: 2em;
  margin-right: 0.25em;
}
.clesy-order-create .add-article-dialog .empty-message {
  text-align: center;
  font-style: italic;
  padding: 2em 0;
}
.clesy-order-create .add-article-dialog .price {
  flex: 0 0 auto;
}
.clesy-order-create .add-article-dialog .clesy-card-content {
  overflow-y: scroll;
  flex: 1;
}
.clesy-order-create .add-article-dialog .clesy-card-footer {
  height: 50px;
  border-radius: 0;
  background-color: #294b78;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
}
@keyframes tremble {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(27deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-27deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.clesy-order-create .add-article-dialog .clesy-card-footer .shopping-bag {
  font-size: 2em;
  margin-right: 20px;
}
.clesy-order-create .add-article-dialog .clesy-card-footer .shopping-bag--animated {
  animation-name: tremble;
  animation-duration: 0.5s;
}
.clesy-order-create .add-article-dialog .clesy-card-footer .item-count {
  display: block;
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  color: #294b78;
  background: white;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  margin-right: 20px;
}
.clesy-order-create .add-article-dialog .clesy-card-footer .total-amount {
  display: block;
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  font-weight: 500;
  z-index: 1;
  margin-right: 30px;
}
.clesy-order-list .header {
  justify-content: center;
  text-align: center;
}
@media (min-width: 40em) {
  .clesy-order-list .header {
    text-align: left;
  }
}
.clesy-order-list .content,
.clesy-order-list .header {
  padding: 0 5%;
}
@media (min-width: 40em) {
  .clesy-order-list .content,
  .clesy-order-list .header {
    padding: 0;
  }
}
.clesy-order-list .no-orders {
  font-style: italic;
  font-size: 1.5em;
  color: #fff;
  margin: 1.5em 0;
  text-align: center;
}
.clesy-order-list .no-orders a {
  cursor: pointer;
  text-decoration: underline;
}
.clesy-order-list .orders .items-and-total {
  margin: 1em 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
}
.clesy-order-list .orders .items-and-total .order-items {
  opacity: 0.75;
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
}
.clesy-order-list .orders .items-and-total .order-items span {
  display: block;
}
.clesy-order-list .orders .items-and-total .order-total {
  text-align: right;
  flex: 0 0 auto;
}
.clesy-order-list .orders .items-and-total .order-total .label {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
}
.clesy-order-list .orders .items-and-total .order-total .value {
  font-size: 1.71428571em;
  font-weight: 400;
  text-transform: none;
}
.clesy-order-list .orders .clesy-card-actions {
  text-align: center;
}
.clesy-order-list .orders .clesy-card-actions > * {
  margin: 0 0.5em;
}
.clesy-order-view .clesy-card-actions {
  margin: 2em 0;
  text-align: center;
}
.clesy-order-view .clesy-card-actions > * {
  margin: 0 0.5em;
}
.clesy-order-view .clesy-card-actions a {
  text-decoration: none;
}
.clesy-order-view .cancel-button {
  padding: 2em 0;
  text-align: center;
}
.clesy-order-view .cancel-button .button {
  border-color: #fff;
  color: #fff;
}
.clesy-order-clearing .clearing-success {
  text-align: center;
}
.clesy-order-clearing .clearing-wait {
  text-align: center;
  margin-bottom: 2.5em;
}
.clesy-order-clearing .clesy-field.radio-field > label {
  display: flex;
  margin: 0.75em 0;
}
.clesy-order-clearing .clesy-field.radio-field > label > input {
  vertical-align: middle;
}
.clesy-order-clearing .clesy-field.radio-field > label > .option {
  flex: 1 1 auto;
}
.clesy-order-clearing .number-holder {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
}
.clesy-order-clearing .number-holder .clesy-field:first-child {
  flex: 1 1 auto;
  min-width: 12em;
}
.clesy-order-clearing .number-holder .clesy-field:last-child {
  flex: 1 1 auto;
  min-width: 12em;
}
.clesy-order-clearing .expiry-code {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
}
.clesy-order-clearing .expiry-code .clesy-field:first-child {
  flex: 1 1 auto;
  min-width: 12em;
}
.clesy-order-clearing .expiry-code .clesy-field:last-child {
  flex: 0 0 4em;
}
.clesy-order-clearing .iban-bic {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
}
.clesy-order-clearing .iban-bic .clesy-field:first-child {
  flex: 2 1 auto;
  min-width: 12em;
}
.clesy-order-clearing .iban-bic .clesy-field:last-child {
  flex: 1 1 auto;
  min-width: 8em;
}
.clesy-order-clearing .errors .error {
  margin: 1em 0;
  background: rgba(102, 0, 0, 0.1);
  color: #660000;
  padding: 1em;
  border-radius: 0.25em;
}
.clesy-order-clearing-failure .error-message {
  background: rgba(102, 0, 0, 0.1);
  color: #660000;
  padding: 1em;
  margin: 1em 0;
  border-radius: 0.25em;
}
.clesy-order-clearing-failure .cancel-button {
  padding: 2em 0;
  text-align: center;
}
.clesy-order-clearing-failure .cancel-button .button {
  border-color: #fff;
  color: #fff;
}
.clesy-card-content.cube-pin-card-content .cube-pin {
  margin: 0 auto;
  padding: 1em 0;
  text-align: center;
}
.clesy-card-content.cube-pin-card-content .cube-pin .label {
  margin-bottom: 1em;
}
.clesy-card-content.cube-pin-card-content .cube-pin .value {
  font-size: 1.42857143em;
  font-weight: 500;
  text-transform: none;
}
.clesy-card-content.cube-pin-card-content .cube-pin .value span {
  display: inline-block;
  line-height: 2;
  width: 2em;
  border-radius: 1em;
  margin: 0 0.125em;
  background: #294b78;
  color: #fff;
}
.clesy-card-content.cube-pin-card-content .cube-pin .pinInfo {
  padding-top: 1.5em;
  padding-left: 3em;
  padding-right: 3em;
  font-size: 0.75em;
}
.clesy-card-header.order-status {
  opacity: 0.75;
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.clesy-card-content.order-details {
  padding: 0.5em 0;
}
.clesy-card-content.order-details table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.clesy-card-content.order-details th,
.clesy-card-content.order-details tfoot {
  font-size: 1em;
  font-weight: 500;
  text-transform: none;
}
.clesy-card-content.order-details td,
.clesy-card-content.order-details th {
  padding: 0.25em 0;
}
.clesy-card-content.order-details tbody tr:nth-child(odd) {
  background: #f6f6f6;
}
.clesy-card-content.order-details tfoot {
  border-top: 1px solid #ccc;
  border-bottom: 3px double #ccc;
}
.clesy-card-content.order-details tfoot td {
  padding: 0;
}
.clesy-card-content.order-details tfoot tr:first-child td {
  padding-top: 0.25em;
}
.clesy-card-content.order-details tfoot tr:last-child td {
  padding-bottom: 0.25em;
}
.clesy-card-content.order-details .name {
  text-align: left;
  width: 60%;
}
.clesy-card-content.order-details .quantity {
  text-align: center;
  width: 20%;
  min-width: 4em;
}
.clesy-card-content.order-details .total {
  text-align: right;
  width: 20%;
  min-width: 4em;
  white-space: nowrap;
}
.clesy-card-content.order-details .tax {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
}
.clesy-card-content.order-details .order-meta > * {
  margin: 1em 0;
}
.clesy-card-content.order-details .order-meta .label {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
}
.clesy-card-content.order-details .delivery-dates {
  display: flex;
  flex-flow: row wrap;
}
.clesy-card-content.order-details .delivery-dates .date {
  flex: 1 1 auto;
}
.clesy-card-content.order-details .delivery-dates .attention {
  margin-top: 1em;
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
}
.order-newer-version .message {
  background: rgba(102, 0, 0, 0.1);
  color: #660000;
  padding: 1em;
  margin: 1em 0;
  border-radius: 0.25em;
}
.order-newer-version .actions {
  margin: 1em 0;
  text-align: center;
}
.clesy-lost-password-view .clesy-logo,
.clesy-reset-password-view .clesy-logo {
  font-size: 3em;
  margin: 1em auto;
}
.clesy-lost-password-view .buttons,
.clesy-reset-password-view .buttons {
  margin: 1em 0;
  padding-bottom: 20px;
  padding-top: 20px;
}
.clesy-lost-password-view .buttons #left,
.clesy-reset-password-view .buttons #left {
  float: left;
}
.clesy-lost-password-view .buttons #right,
.clesy-reset-password-view .buttons #right {
  float: right;
}
.clesy-lost-password-view .cancel-button,
.clesy-reset-password-view .cancel-button {
  padding: 2em 0;
  text-align: center;
}
.clesy-lost-password-view .cancel-button .button,
.clesy-reset-password-view .cancel-button .button {
  border-color: #fff;
  color: #fff;
}
.clesy-referral .buttons {
  margin-top: 1em;
  text-align: center;
}
.clesy-referral .error-message {
  margin: 2em 0 0 0;
  background: rgba(102, 0, 0, 0.1);
  color: #660000;
  padding: 1em;
  border-radius: 0.25em;
}
.clesy-settings-view .success-message,
.clesy-settings-view .error-message {
  margin: 2em 0 0 0;
  padding: 1em;
  border-radius: 0.25em;
}
.clesy-settings-view .error-message {
  background: rgba(102, 0, 0, 0.1);
  color: #660000;
}
.clesy-settings-view .success-message {
  background: rgba(0, 102, 0, 0.1);
  color: #006600;
}
.clesy-settings-view .header {
  justify-content: center;
  text-align: center;
}
@media (min-width: 40em) {
  .clesy-settings-view .header {
    text-align: left;
  }
}
.clesy-settings-view .content,
.clesy-settings-view .header {
  padding: 0 5%;
}
@media (min-width: 40em) {
  .clesy-settings-view .content,
  .clesy-settings-view .header {
    padding: 0;
  }
}
.clesy-settings-view .clesy-card {
  margin-bottom: 1em;
}
.clesy-settings-view .row {
  display: flex;
  flex-flow: row wrap;
}
.clesy-settings-view .row > div {
  flex: 1 1 auto;
  min-width: 10em;
}
.clesy-settings-view .row .label {
  font-size: 0.85714286em;
  font-weight: 400;
  text-transform: none;
  opacity: 0.75;
}
.clesy-settings-view .buttons {
  margin: 1em 0 0 0;
  text-align: center;
}
select::-moz-focus-inner {
  /*Remove button padding in FF*/
  border: 0;
  padding: 0;
}
.no-wrap {
  white-space: nowrap;
}
